import React, { useState } from 'react';
import { AssetV2 } from 'types/asset.type';
import AssetManagementAssetMenuItemsSetAvailable from 'components/asset-management/components/asset-menu-items/set-available';
import AssetManagementAssetMenuItemsSetToDraft from 'components/asset-management/components/asset-menu-items/set-to-draft';
import AssetManagementAssetMenuItemsRestoreToDraft from 'components/asset-management/components/asset-menu-items/restore-to-draft';
import AssetManagementAssetMenuItemsMoveToBin from 'components/asset-management/components/asset-menu-items/move-to-bin';
import AssetManagementAssetMenuItemsDeletePermanently from 'components/asset-management/components/asset-menu-items/delete-permanently';
import AssetManagementAssetMenuItemsOrganise from 'components/asset-management/components/asset-menu-items/organise';
import AssetManagementAssetMenuItemsCopy from 'components/asset-management/components/asset-menu-items/copy';
import AssetManagementMenuItemsWrapper, { AssetManagerMenuButtonType } from 'components/asset-management/components/menu-items-wrapper';
import { CreativeAsset } from 'components/creative-management-v2/types/creative-management.type';

interface Props {
    asset: CreativeAsset;
    buttonType?: AssetManagerMenuButtonType;
    onSetAssetToRemove: (asset: AssetV2<unknown, unknown>, permanent: boolean) => void;
    onSetAssetMenuOpen?: (open: boolean) => void; // Used in case we want a callback to the parent component when the menu is opened or closed.
}

const CreativeManagementAssetMenu = ({ asset, buttonType, onSetAssetToRemove, onSetAssetMenuOpen }: Props) => {
    const [waiting, setWaiting] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const handleMenu = (anchorEl: HTMLElement | undefined) => {
        setAnchorEl(anchorEl);
        if (onSetAssetMenuOpen) onSetAssetMenuOpen(Boolean(anchorEl));
    };

    return (
        <AssetManagementMenuItemsWrapper buttonType={buttonType} open={Boolean(anchorEl)} anchorEl={anchorEl} waiting={waiting} onSetAnchorEl={handleMenu}>
            <AssetManagementAssetMenuItemsCopy asset={asset} onClose={() => handleMenu(undefined)} onSetWaiting={setWaiting} />
            <AssetManagementAssetMenuItemsOrganise asset={asset} onClose={() => handleMenu(undefined)} />
            <AssetManagementAssetMenuItemsSetAvailable asset={asset} onClose={() => handleMenu(undefined)} onSetWaiting={setWaiting} />
            <AssetManagementAssetMenuItemsSetToDraft asset={asset} onClose={() => handleMenu(undefined)} onSetWaiting={setWaiting} />
            <AssetManagementAssetMenuItemsRestoreToDraft asset={asset} onClose={() => handleMenu(undefined)} onSetWaiting={setWaiting} />
            <AssetManagementAssetMenuItemsMoveToBin asset={asset} onClose={() => handleMenu(undefined)} onSetAssetToRemove={onSetAssetToRemove} />
            <AssetManagementAssetMenuItemsDeletePermanently asset={asset} onClose={() => handleMenu(undefined)} onSetAssetToRemove={onSetAssetToRemove} />
        </AssetManagementMenuItemsWrapper>
    );
};

export default CreativeManagementAssetMenu;
