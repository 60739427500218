import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import Icon from '@mui/material/Icon';
import { AssetV2 } from 'types/asset.type';
import ToolIconButton from 'components/ui-components/ToolIconButton';
import AMCheckbox from 'components/asset-management/components/am-checkbox';
import { useAMNavigation } from 'components/asset-management/hooks/';
import { canOrganise } from 'components/asset-management/utilities';
import AssetManagementActionsWrapper from 'components/asset-management/components/actions/actions-wrapper';
import useAMAssetSelection from 'components/asset-management/hooks/useAMAssetSelection';
import Translation from 'components/data/Translation';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import { CreativeAsset } from '../../../types/creative-management.type';
import CreativeManagementAssetMenu from '../../asset-menu';
import CreativeManagementAssetTileInfo from './info';
import CreativeManagementAssetTilePreview from './preview';

import '../styles/main.scss';

interface Props {
    asset: CreativeAsset;
    onSetAssetToRemove: (asset: AssetV2<unknown, unknown>, permanent: boolean) => void;
}

const CreativeManagementAssetTile = ({ asset, onSetAssetToRemove }: Props) => {
    const { openAssetDetailOverlay } = useAMNavigation();
    const { userHasRight } = useAssetManagementConfigContext();
    const [hovered, setHovered] = useState(false);
    const canOrg = useMemo(() => canOrganise(userHasRight, asset.status), [asset.status]);

    const { isSelected } = useAMAssetSelection(asset._id);

    // When we close the menu, we want to set the hovered state to false because onMouseLeave isn't called in that scenario.
    const stopHoverOnMenuClose = (open: boolean) => {
        if (!open) {
            setHovered(false);
        }
    };

    return (
        <div
            id={`asset_${asset._id}`}
            data-tour-selector="asset-library-item"
            className={classNames('creative-management-asset-tile', 'asset-management-selectable-asset', {
                'creatives-management-asset-tile--selected': isSelected
            })}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => openAssetDetailOverlay(asset._id)}>
            <div
                className={classNames('creative-management-asset-tile__preview', {
                    'creative-management-asset-tile__preview--selected': isSelected
                })}>
                <CreativeManagementAssetTilePreview creative={asset} />
                {(hovered || isSelected) && (
                    <div className="creative-management-asset-tile__overlay">
                        <AMCheckbox asset={asset} className={'creative-management-asset-tile__checkbox'} />
                        {hovered && (
                            <React.Fragment>
                                <AssetManagementActionsWrapper className="creative-management-asset-tile__tools">
                                    <Tooltip title={Translation.get('actions.openPreview', 'asset-management')}>
                                        <span>
                                            <ToolIconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openAssetDetailOverlay(asset._id);
                                                }}>
                                                <Icon>open_in_full</Icon>
                                            </ToolIconButton>
                                        </span>
                                    </Tooltip>
                                    {canOrg && (
                                        <Tooltip title={Translation.get('labels.organise', 'common')}>
                                            <span>
                                                <ToolIconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openAssetDetailOverlay(asset._id, 'organise');
                                                    }}>
                                                    <Icon>bookmarks_outline</Icon>
                                                </ToolIconButton>
                                            </span>
                                        </Tooltip>
                                    )}
                                    <CreativeManagementAssetMenu
                                        asset={asset}
                                        buttonType="toolIcon"
                                        onSetAssetToRemove={onSetAssetToRemove}
                                        onSetAssetMenuOpen={stopHoverOnMenuClose}
                                    />
                                </AssetManagementActionsWrapper>
                                <div className="creative-management-asset-tile__label">{Translation.get('asset', 'creative-management-v2')}</div>
                            </React.Fragment>
                        )}
                    </div>
                )}
            </div>
            <CreativeManagementAssetTileInfo title={asset.title} subtitle={Translation.get(`sub_types.${asset.subType}`, 'creative-management-v2')} />
        </div>
    );
};

export default CreativeManagementAssetTile;
