// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Roboto-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* Units are deprecated, use the $s* variables instead */\n/* Sizes using rem. This is based on a default 16px */\n/* This is to provide better control and consistency in managing stacking components */\n/* Custom sizes */\n@font-face {\n  font-family: \"Roboto\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-style: normal;\n}\n.google-text-ad {\n  font-family: \"Roboto\", sans-serif;\n  width: 320px;\n  border-radius: 8px;\n  min-height: 100px;\n  padding: 16px;\n  background: white;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);\n}\n.google-text-ad .top-section {\n  display: flex;\n  justify-content: space-between;\n  position: relative;\n}\n.google-text-ad .top-section .headline-wrapper {\n  font-size: 13px;\n  color: #1a0dab;\n  -webkit-line-clamp: 2;\n  line-height: 20px;\n}\n.google-text-ad .top-section .icon-wrapper .info-icon {\n  opacity: 0.5;\n  width: 0.8em;\n  height: 0.8em;\n}\n.google-text-ad .url-wrapper {\n  width: 100%;\n  color: #006621;\n  font-size: 11px;\n}\n.google-text-ad .url-wrapper .adv-badge {\n  border: 1px solid #006621;\n  padding: 0 2px;\n}\n.google-text-ad .url-wrapper .adv-link {\n  margin-left: 8px;\n}\n.google-text-ad .line-break {\n  background: #ebebeb;\n  border: 0;\n  height: 1px;\n  position: relative;\n  margin: 10px 0 6px 0;\n}\n.google-text-ad .description-wrapper {\n  font-size: 13px;\n  color: #545454;\n}", ""]);
// Exports
module.exports = exports;
