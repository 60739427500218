import React from 'react';
import '../styles/main.scss';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';

class PreviewMobileWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayType: 'standalone'
        };
    }

    handleChange = (event, value) => {
        this.setState({
            displayType: value
        });
    };

    handleClose = () => {
        this.setState({
            displayType: 'standalone'
        });
    };

    render() {
        const { children, preview = 'youtube' } = this.props;
        const { displayType } = this.state;

        const adType = children.props.data.adType;

        return (
            <div className="preview-wrapper">
                {displayType == 'mobile' && (
                    <div className="preview-mobile-wrapper">
                        <div className="mobile-header"></div>
                        {preview == 'youtube' && <div className="youtube-header"></div>}

                        {preview == 'twitter' && <div className="twitter-header"></div>}

                        {preview == 'linkedin' && <div className="linkedin-header"></div>}

                        {preview == 'snapchat' && adType !== 'single' && <div className="snapchat-header"></div>}

                        {preview == 'facebook' && adType !== 'storiesAd' && <div className="facebook-header"></div>}

                        {preview == 'instagram' && adType !== 'storiesAd' && <div className="instagram-header"></div>}

                        {(preview == 'instagram' || preview == 'facebook') && adType === 'storiesAd' && <div style={{ height: 20 }}></div>}

                        <div className="mobile-main-container">{children}</div>

                        <div className="mobile-footer">
                            <div className="container"></div>
                        </div>
                    </div>
                )}

                {displayType == 'standalone' && <div className="preview-standalone-wrapper">{children}</div>}

                {displayType == 'float' && (
                    <div className="preview-float-wrapper">
                        <div className="close-bar">
                            <IconButton aria-label="Close" onClick={this.handleClose} size="small">
                                <Icon>close</Icon>
                            </IconButton>
                        </div>
                        {children}
                    </div>
                )}

                <div className="toggle-container">
                    <ToggleButtonGroup value={displayType} exclusive onChange={this.handleChange}>
                        <ToggleButton key={1} value="mobile">
                            <Icon>mobile_friendly</Icon>
                        </ToggleButton>
                        <ToggleButton key={2} value="standalone">
                            <Icon>rounded_corner</Icon>
                        </ToggleButton>
                        <ToggleButton key={3} value="float">
                            <Icon>launch</Icon>
                        </ToggleButton>
                        ,
                    </ToggleButtonGroup>
                </div>
            </div>
        );
    }
}

export default PreviewMobileWrapper;
