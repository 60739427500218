import React from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';

import '../styles/info.scss';

interface Props {
    title: string;
    subtitle: string;
    small?: boolean;
}

const CreativeManagementAssetTileInfo = ({ title, subtitle, small }: Props) => {
    return (
        <div className="creative-management-asset-tile-info ">
            <Icon baseClassName="material-icons material-icons-outlined" className="creative-management-asset-tile-info__icon">
                star_outline
            </Icon>
            <div className="creative-management-asset-tile-info__text">
                <div className={classNames('creative-management-asset-tile-info__title', { 'creative-management-asset-tile-info__title--small': small })}>
                    {title}
                </div>
                <div className="creative-management-asset-tile-info__subtitle">{subtitle}</div>
            </div>
        </div>
    );
};

export default CreativeManagementAssetTileInfo;
