// The default setup is used when no custom setup is present in the resources.

import { AssetManagementSetup } from 'components/asset-management/types/asset-management-setup.type';

export const CREATIVE_MANAGEMENT_DEFAULT_SETUP: AssetManagementSetup = {
    assetFields: [
        {
            name: 'Creative title',
            key: 'title',
            type: 'textfield',
            editable: true,
            filterable: false,
            required: true
        },
        {
            name: 'Collection',
            key: 'collections',
            type: 'selectMultiple',
            editable: true,
            filterable: false,
            required: false
        },
        {
            name: 'Brands',
            key: 'brands',
            type: 'selectMultiple',
            editable: true,
            filterable: true,
            required: false
        },
        {
            name: 'Departments',
            key: 'departments',
            type: 'selectMultiple',
            editable: true,
            filterable: true,
            required: false
        },
        {
            name: 'Markets',
            key: 'markets',
            type: 'selectMultiple',
            editable: true,
            filterable: true,
            required: false
        },
        {
            name: 'Creative type',
            key: 'subType',
            type: 'selectMultiple',
            editable: false,
            filterable: true,
            required: false
        },
        {
            name: 'Tags',
            key: 'tags',
            type: 'selectMultiple',
            editable: true,
            filterable: true,
            required: false,
            optionsFromBackEnd: true
        }
    ],
    assetFieldsGrouping: [
        {
            title: 'General',
            icon: 'title',
            open: true,
            inputs: ['title']
        },
        {
            title: 'Availability',
            icon: 'lock_open',
            open: true,
            inputs: ['brands', 'markets', 'departments']
        },
        {
            title: 'Details',
            icon: 'new_label',
            open: true,
            inputs: ['tags', 'collections']
        }
    ],
    assetFilterOrder: ['subType', 'brands', 'markets', 'departments', 'tags'],
    displayType: 'list'
};
