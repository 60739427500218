import React from 'react';
import { SelectorAssetSourceData } from 'components/asset-management/components/selector/types/selector-asset-source-data.type';
import Translation from 'components/data/Translation';
import { TemplateAsset } from 'components/template-management/types/template-management.type';
import Button from 'components/ui-components-v2/Button';
import { CreativeAsset } from 'components/creative-management-v2/types/creative-management.type';
import { CreativeManagementAssetTileInfo, CreativeManagementAssetTilePreview } from '../../asset-tile';

import '../styles/tile.scss';

interface Props {
    item?: SelectorAssetSourceData;
    onSelect?: (item: TemplateAsset) => void;
}

const CreativeManagementSelectorTile = ({ item, onSelect }: Props) => {
    if (!item?.originalRecord) return null;
    if (!onSelect) return null;

    // Select the asset and make sure we notify the usage endpoint of this selection.
    const handleSelect = (item: SelectorAssetSourceData) => {
        //TODO: Add usage logic here.

        onSelect(item.originalRecord as TemplateAsset);
    };

    return (
        <div onClick={() => handleSelect(item)} className="creative-management-selector-tile">
            <div className="creative-management-selector-tile__preview">
                <div className="creative-management-selector-tile__overlay">
                    <Button variant="contained" color="primary" className="creative-management-selector-tile__button">
                        {Translation.get('actions.select', 'common')}
                    </Button>
                </div>
                <CreativeManagementAssetTilePreview creative={item.originalRecord as CreativeAsset} />
            </div>
            <CreativeManagementAssetTileInfo
                title={item.title || ''}
                subtitle={Translation.get(`sub_types.${item.originalRecord.subType}`, 'creative-management-v2')}
                small
            />
        </div>
    );
};

export default CreativeManagementSelectorTile;
