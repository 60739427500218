import React from 'react';
import PropTypes from 'prop-types';
import GoogleTextAd from './google-text-ad';
import './../styles/main.scss';

class PreviewGoogle extends React.Component {

    static propTypes = {
        adType: PropTypes.string,
        advertiser: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
        }),
        headline: PropTypes.string,
        displayUrl: PropTypes.string
    };

    static defaultProps = {
        adType: 'textAd'
    };

    static getChild(adType, props) {
        let component = null;

        switch (adType) {
            case 'textAd':
                component = (<GoogleTextAd {...props} />);
                break;

            default:
                component = null;
        }

        return component;
    }

    render() {
        const { adType } = this.props;

        return (
            <div>
                {PreviewGoogle.getChild(adType, this.props)}
            </div>

        );
    }
}

export default PreviewGoogle;
